/* --- =ie8 stylesheet - media query polyfill not working - need some fixes --- */

body.ie8 {
	background-color: #539ad2;
	&.full-width #footer {
		padding: 15px;
	}
	.top-for-smaller-screens {
		.icon-phone {
			display: none;
		}
		.phone-number {
			display: none;
		}
	}
	#header {
		.phone-for-larger {
			display: block;
		}
	}
	#logo .site-description {
		display: block;
	}
}